<script>
  import { mapActions } from "vuex";
  import "@vueform/multiselect/themes/default.css";
  import PageHeader from "@/components/page-header";

  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import Lottie from "@/components/widgets/lottie.vue";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'QTableJournalListView',
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Lista cantieri",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Giornale Lavori Piano Qualità",
        dataSumm:{},
        detailProject:{},
        detailWorksite:{},
        quality:{},
        items: [
        ],
        date3: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        filterdate: null,
        filterdate1: null,
        filtervalue: 'All',
        filtervalue1: 'All',
        filtersearchQuery1:null,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 20,
        sortRes:'asc',
        value: 'All',
        pages: [],
        responseList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        form:{
          start:'',
          end:'',
          status:'init',
          select:'',
        },
        n_results:0,
        minDate: moment().format('YYYY-MM-DD HH:MM'),
        maxDate: moment().format('YYYY')+ '-12-31 HH:MM',
        minDateEnd:moment().format('YYYY-MM-DD HH:MM'),
        maxDateEnd: moment().format('YYYY') + '-12-31 HH:MM',
      };
    },
    components: {
      PageHeader,
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      //this.getList()
    },

    methods: {
      convertDate(date){
        if (date) {
          moment.locale('it')
          return moment.unix(date).format('DD/MM/YYYY')
        }
      },
      convertDateLLL(date){
        if (date) {
          moment.locale('it')
          return moment(date).format('LLLL')
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.form.start
          this.form.end = this.form.start
        }
        if (type=='end'){
          this.minDateEnd = this.form.start
        }
      },
      ...mapActions('layout', ['changeLayoutType', ]),
    
        axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })

                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }            
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getSummary() {
        let year = new Date().getFullYear()
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}projects/summary/${year.toString()}`, {
            headers: { 'authorization':this.tk
          }}).then((response) => {
            if (response.data) {
              this.dataSumm = response.data
            }        
          })
      },
      getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}quality/journal/byqp`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.$route.params.id,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.item = response.data
          if (response.data.quality){
            if (response.data.quality.length>0){
              this.quality = response.data.quality[0]
              if(response.data.quality[0].project.length>0){
                this.detailProject = response.data.quality[0].project[0]
              }
              if(response.data.quality[0].worksite.length>0){
                this.detailWorksite = response.data.quality[0].worksite[0]
              }
            }

            this.items = [
              {
                text: "Elenco commesse per Piano di Qualità",
                href: "/quality-control/list-view",
              },
              {
                text: "Dettaglio Piano di Qualità",
                href: `/quality-control/detail/${this.quality.projectID}/${this.quality._id}`,
              },
              {
                text: "Giornale Lavori Piano Qualità",
                active: true,
              },
          ];

          }
          this.responseList = response.data.results.data
          this.paginated = response.data.results
          this.n_results = response.data.n_results
        })
      },
      SearchData(){
        //if (this.form.start!='' && this.form.end!=''){
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}quality/journal/byqp/filtered`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.$route.params.id,
                search: this.search,
                start:moment(this.form.start).unix(),
                end:moment(this.form.end).unix(),
                status:this.form.status,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.responseList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
            this.createQueryString()
          })
       // }
      },
      filterOptions(val){
        switch(val){
          case "azienda":
            this.getSettingsOptions("tax-benefit-business")
          break;
          case "privato":
            this.getSettingsOptions("tax-benefit-private")
          break;
          case "pubblico":
            this.getSettingsOptions("tax-benefit-pa")
          break;
          default:
          this.optionsFiscalBenefit = []
        }   
      },
      getSettingsOptions(tag){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/tag/${tag}`, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          if (response.data) {
            this.optionsFiscalBenefit = []
            for (let item of response.data) {
              this.optionsFiscalBenefit.push({
                value:item.value,
                label: item.name
              })
            }
            
          }
        })
      },
      editdata(data) {
       
        this.$router.push('/quality-control/detail/'+data.projectID+'/'+data._id)
      },

      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('createModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },
      initItem(){
        Swal.fire(
            {
              title: this.$t("t-createJournalQP-confirm-setting-title"),
              text: this.$t("t-createJournalQP-confirm-setting-text"),
              icon: this.$t("t-createJournalQP-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-createJournalQP-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-createJournalQP-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-createJournalQP-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-createJournalQP-confirm-setting-confirmButtonText")
            }
          ).then((result) => {
            if (result.value) {
              try {
               // Create
               this.submitted = true;
                
                this.v$.$touch();

                this.item.name = 'Giornale'
                this.item.dateString = moment().format('YYYY-MM-DD')
                this.item.startStr= moment().format('YYYY-MM-DD')
                this.item.month = moment().format('YYYY')
                this.item.day = moment().day()
                this.item.month = moment().month()
                this.item.year = parseInt(moment().format('YYYY'))
                this.item.start = moment().valueOf()
                this.item.start_tmp = moment().unix()
                this.item.createdBy = this.datastore.auth.currentUser
                this.item.projectID = this.quality.projectID
                this.item.qualityID = this.quality._id
   
                this.item.meteoDesc = '' 
                this.item.annotazioni = '' 
                this.item.osservazioniRUP = ''   // RUP
                this.item.osservazioniDL = ''   // Dir. Lavori
                this.item.osservazioniCE = ''   // Coord: Esecuzione
                this.item.osservazioniCS = ''   // Coord. Sicurezza
                this.item.osservazioniCOLL = ''  // Collaudatore

                  axios.post(`${this.UrlServer}quality/journal/register`, this.item, {headers: { authorization:this.tk}} )
                    .then((response)=>{
                      if (response.data ){
                        let self = this

                        Swal.fire({  
                          title: this.$t("t-msg-journal-init") , 
                          text: this.$t("t-msg-journal-init-content"), 
                          icon:"success",              
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:3000
                          }).then(()=>{

                            if (response.data) {
                              
                                self.$router.push('/quality-control/detail/journal/'+response.data._id)
                              
                            }
                            
                          });
                      }

                      
                    })
               // /Create
              }catch(error){
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

            }
          });
       
      },
      
      deletedata(id) {
          Swal.fire(
            {
              title: this.$t("t-delete-confirm-setting-title"),
              text: this.$t("t-delete-confirm-setting-text"),
              icon: this.$t("t-delete-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
            }
          ).then((result) => {
            if (result.value) {
              try {
                this.axiosInterceptor()
                axios.delete(`${this.UrlServer}projects/${id}`, {headers: { authorization:this.tk}})
                  .then((response) => {
                    if (response.data) {
                      //this.getList()
                      Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                    }

                  })
              }catch(error){
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

            }
          });
      },  
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(customerList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return customerList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        //this.getList()
        this.SearchData()
      },
      splitStr(str,car){
        if (str){
          return str.replaceAll(car,' ')
        } else {
          return ''
        } 
      },
      createQueryString(){
        this.$router.push(this.$route.path+`?perPage=${this.perPage}&page=${this.page}&search=${this.search}&start=${moment(this.form.start).unix()}&end=${moment(this.form.end).unix()}&status=${this.form.status}`)
      },
    },
    mounted() {
     
      this.getList()



    }
  };
</script>

<template>
    <PageHeader :title="title" :items="items" />

<!-- Modulo tabella elenco progetti/cantieri -->
<div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
            <template v-if="quality.project">
                <span class="mdi mdi-24px mdi-account-details pr-5 "></span>
                <template v-if="detailProject.customerDetails">{{ detailProject.customerDetails.ragSoc }}</template>
                 - Localizzazione: 

                        {{ detailWorksite.address }} {{ detailWorksite.city }}
            </template>
            </h4>
            <div class="flex-shrink-0">
                <template v-if="detailProject">
                    Tipo impianto: 
                   <strong> {{ detailProject.systemSubCategory }}</strong>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Ricerca tra tutte le giornaliere</h5>
              <div class="flex-shrink-0">

                <button class="btn btn-secondary add-btn" href="javascript:void(0)" @click="initItem">
                  <i class="ri-add-line align-bottom me-1"></i>
                  <i class="mdi mdi-notebook align-bottom me-1"></i>
                    Inserisci registrazione
                </button>
            
              </div>
            </div>
          </div>
      
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <div class="mb-5">
              <div class="row rowSearchFormBGce g-4 mb-5">
                
                  <div class="col-lg-5 col-md-12">
                    <div class="">
                      <div class="search-box ms-2">
                        <input type="text" class="form-control"  :placeholder="$t('t-search-for') "  v-model="search"  />
                        <i class="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-from')"   class="form-control" v-model="form.start" :min="minDate" :max="maxDate" @change="updateDate('start')">
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-to')"   class="form-control" v-model="form.end" :min="minDateEnd" :max="maxDateEnd"  @change="updateDate('end')" >
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <select class="form-select" v-model="form.status">
                       <option value="all">Tutti</option>
                       <option value="init">Non assegnati</option>
                       <option value="assigned">Assegnati</option>
                       <option value="pending">Lavorazione in corso</option>
                       <option value="blocked">Bloccati</option>
                       <option value="canceled">Annullati</option>
                       <option value="completed">Completati</option>
                    </select>

                  </div>
                  <div class="col-lg-1 col-md-12">
                    <button type="button" class="btn btn-primary w-100" @click="SearchData">
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      {{ $t('t-filters') }}
                    </button>
                  </div>
                
              </div>
            </div>
            <div class="p-2">
               
            </div>
           <div class="table-card mt-2">
            <div class="row">
              <div class="col-lg-4 col-md-12 pl-20">
                <h5 class="card-title mb-0 flex-grow-1">
                  Risultati: <span v-if="n_results>0" class="text-green bold">{{ n_results }}</span>  <span v-if="n_results==0" class="text-red bold">{{ n_results }}</span>
                </h5>
              </div>
              <div class="col-lg-8 col-md-12">
              </div>
            </div>
                <div class="row thRow mt-50">
                    <div class="col-lg-2 col-md-12">
                        Data e Orario
                    </div>
                  
                    <div class="col-lg-2 col-md-12 text-left">
                        Meteo
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Annotazioni Speciali e Generali
                    </div>
                    
                    <div class="col-lg-2 col-md-12">
                      Operai e Mezzi d'opera impiegati
                    </div>
                    <div class="col-lg-2 col-md-12">
                     Osservazioni e Istruzioni
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                        Azioni
                    </div>
                </div>
              
              <div class="row list tRow form-check-all d-flex" v-for="(item, index) of responseList" :key="index">
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" v-if="item.startStr">
                         <strong>{{ convertDate(item.start_tmp) }}</strong>
                         
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                        <div class="list-inline hstack gap-2 mb-0">
                            {{ item.meteoDesc }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                        <div class="list-inline hstack gap-2 mb-0">
                            {{ item.annotazioni }}

                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                        <div class="list-inline hstack gap-2 mb-0">
                           <!-- <pre>{{ item }}</pre>  --> 
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                        <div class="list-inline hstack gap-2 mb-0">
                            
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                      <ul class="list-inline mt-5">
                        <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica Registrazione Giornaliera">
                          <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/quality-control/detail/journal/'+item._id)">
                            <i class="mdi mdi-clipboard-edit-outline mdi-24px color-greenAccent"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                </div>
                <div class="d-flex tRow justify-content-end mt-3 mb-5" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <template v-for="index in paginated.total_pages" :key="index">
                                <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }"  v-if="index<5">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                            </template>

                        
                        </ul>
                       
                        <template v-if="page>=5">
                            ...
                            <ul class="pagination listjs-pagination mb-0" >
                                <li class="active"  >
                                <a class="page" href="javascript:void(0)">{{ page}}</a>
                            </li>
                            
                        </ul>
                        </template>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
            </div>
           
           <!-- END TABLE -->

         

          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

<!-- / Modulo tabella elenco progetti/cantieri -->      
</template>